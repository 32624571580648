import Vue from 'vue'
import Element from 'element-ui'
import lang from 'element-ui/lib/locale/lang/en'
import locale from 'element-ui/lib/locale'
import VueSession from 'vue-session'
import Select from 'vue-select2'
import VueMeta from 'vue-meta'
import VueCryptojs from 'vue-cryptojs'
import VueFormWizard from 'vue-form-wizard'

import App from './App.vue'
import store from './store'
import router from './router'

// External Javascript
import '@/assets/js/fontawesome.all.min.js'

// CSS
// Make sure you add custom style at the last becuase
// otherwise it will overwrite..
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import 'element-ui/lib/theme-chalk/index.css'

import '@/assets/css/bootstrap.min.css'
import '@/assets/css/style.css'

const options = {
	persist: true,
}

locale.use(lang)

Vue.use(VueMeta)
Vue.use(Select)

Vue.use(Element)
Vue.use(VueSession, options) // vue session
Vue.use(VueCryptojs) // crypto js
Vue.use(VueFormWizard)

Vue.prototype.$api = process.env.VUE_APP_API_URL
Vue.prototype.$secretKey = process.env.VUE_APP_SECRET_KEY

Vue.config.productionTip = false

new Vue({
	router,
	store,
	render: (h) => h(App),
}).$mount('#app')
