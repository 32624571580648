<template>
	<header>
		<!-- Navigation-->
		<nav class="navbar navbar-expand-lg navbar-dark sticky-top" id="mainNav">
			<div class="container p-md-0">
				<a class="navbar-brand me-sm-4" :href="websiteUrl">
					<img
						:src="require('@/assets/image/shoe-care-clinic-logo.png')"
						alt="Shoe care clinic logo"
					/>
				</a>
				<button
					type="button"
					class="navbar-toggler"
					:class="{ collapsed: !isMobileMenuOpen }"
					aria-label="Toggle navigation"
					:aria-expanded="isMobileMenuOpen ? 'true' : 'false'"
					@click="isMobileMenuOpen = !isMobileMenuOpen"
				>
					<i class="fas fa-bars fa-lg"></i>
				</button>

				<div
					class="collapse navbar-collapse justify-content-end"
					:class="{ show: isMobileMenuOpen }"
				>
					<!-- links -->
					<ul class="navbar-nav py-2 py-lg-0">
						<li class="nav-item" @click="closeMobileMenu">
							<a class="nav-link active" :href="websiteUrl"> Home </a>
						</li>
						<li
							v-for="link in headerLinks"
							:key="link.href"
							class="nav-item"
							@click="closeMobileMenu"
						>
							<a :href="link.href" class="nav-link">{{ link.name }}</a>
						</li>
					</ul>
					<!-- login & cart icons -->
					<div class="d-lg-flex nav_extra mx-lg-2">
						<div class="mb-3 mb-lg-0">
							<div v-if="!isAuthenticated" @click="isMobileMenuOpen = false">
								<router-link
									class="nav-login d-flex justify-content-between"
									:to="{ name: 'login' }"
								>
									<span class="d-block d-lg-none">Login</span>
									<img :src="require('@/assets/image/userVector.png')" alt="User avatar" />
								</router-link>
							</div>
							<user-drop-down v-else @close="isMobileMenuOpen = false" />
						</div>
					</div>
					<!-- book now button -->
					<div @click="closeMobileMenu">
						<router-link
							:to="{ name: 'home' }"
							class="btn-blue text-center text-md-left w-100 d-block d-md-inline-block py-3 px-3 rounded-2"
						>
							<strong>Book Now</strong>
						</router-link>
					</div>
				</div>
			</div>
		</nav>
	</header>
</template>

<script>
import UserDropDown from '@/components/UserDropDown.vue'
import { WEBSITE_URL } from '@/constants'
import { headerLinks } from '@/data/links'

export default {
	name: 'TheHeader',
	components: {
		UserDropDown,
	},
	data() {
		return {
			isMobileMenuOpen: false,
			headerLinks,
			websiteUrl: WEBSITE_URL,
		}
	},
	computed: {
		isAuthenticated() {
			return this.$store.state.token
		},
	},
	methods: {
		closeMobileMenu() {
			this.isMobileMenuOpen = false
		},
	},
}
</script>

<style scoped>
.btn-blue {
	background-color: #0f6cd9;
	color: white !important;
	box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1), 0 4px 2px rgba(0, 0, 0, 0.06);
}
</style>
