import Vue from 'vue'
import VueRouter from 'vue-router'
import { routes } from '@/routes'
import store from '@/store'

Vue.use(VueRouter)

const router = new VueRouter({
	mode: 'history',
	scrollBehavior() {
		return { x: 0, y: 0 }
	},
	routes: [
		...routes,
		// *===============================================---*
		// *--------- ERRORS PAGES  -------------------------------*
		// *===============================================---*
		{
			path: '*',
			name: 'error-404',
			component: () => import('@/views/404.vue'),
			meta: {
				auth: null,
			},
		},
	],
})

const canNavigate = (to, loggedIn = false) => {
	return to.matched.some((route) => {
		if (route.name === 'home' || route.name === 'error-404') {
			return loggedIn
		}
		return route.meta.auth
	})
}

router.beforeEach((to, _, next) => {
	const isAuthenticated = store.getters['IS_AUTHENTICATED']
	if (!isAuthenticated && canNavigate(to)) {
		return next({ name: 'login' })
	}
	// if (isAuthenticated && !canNavigate(to, true)) {
	// 	return next({ name: 'home' })
	// }
	return next()
})

export default router
