import { WEBSITE_URL } from '@/constants'

const links = [
	{
		name: 'Pricing',
		href: `${WEBSITE_URL}/pricing`,
	},
	{
		name: 'Blog',
		href: `${WEBSITE_URL}/blog`,
	},
	{
		name: 'Gallery',
		href: `${WEBSITE_URL}/gallery`,
	},
	{
		name: 'Product',
		href: `${WEBSITE_URL}/product`,
	},
	{
		name: 'Shoe Cleaning',
		href: `${WEBSITE_URL}/shoe-cleaning`,
	},
]

export const headerLinks = [...links]

export const footerLinks = [
	{
		header: 'Quick links',
		children: [
			{
				name: 'Why choose us',
				href: `${WEBSITE_URL}/#WhyChooseUs`,
			},
			{
				name: 'How we work',
				href: `${WEBSITE_URL}/#about`,
			},
			{
				name: 'Blog',
				href: `${WEBSITE_URL}/blog`,
			},
			{
				name: 'All Locations',
				href: `${WEBSITE_URL}/all-locations`,
			},
			{
				name: 'All Directories',
				href: `${WEBSITE_URL}/all-directories`,
			},
		],
	},
	{
		header: 'Resources',
		children: [
			{
				name: 'Home',
				href: `${WEBSITE_URL}`,
			},
			...links, // common links
			{
				name: 'About Us',
				href: `${WEBSITE_URL}/about-us`,
			},
			{
				name: 'Contact Us',
				href: `${WEBSITE_URL}/contact`,
			},
		],
	},
]

export const socialLinks = [
	{
		name: 'Facebook',
		icon: 'fab fa-facebook-f',
		url: 'https://www.facebook.com/onlineshoerepair',
	},
	{
		name: 'Instagram',
		icon: 'fab fa-instagram',
		url: 'https://www.instagram.com/onlineshoerepairs/',
	},
	{
		name: 'LinkedIn',
		icon: 'fab fa-linkedin-in',
		url: 'https://www.linkedin.com/company/online-shoe-repairs',
	},
]
