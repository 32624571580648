<template>
	<div class="header_top d-none d-md-block">
		<div class="container p-0">
			<div class="d-flex justify-content-end">
				<div class="d-flex align-items-center text-white m-0 mx-2 me-0">
					<img
						:src="require('@/assets/image/mailVector.png')"
						alt="Mailbox"
						class="flex-shrink-0"
					/>
					<span class="ml-2 d-inline-block">shoecareclinics@gmail.com</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'HeaderMiniTop',
}
</script>

<style scoped>
.ml-2 {
	margin-left: 0.5rem;
}
</style>
