<template>
	<div class="dropdown px-2 py-1 px-lg-0 py-lg-0">
		<div
			class="w-8 h-8 flex-shrink-0 text-white text-uppercase rounded-circle overflow-hidden"
			@click="isOpen = !isOpen"
		>
			<div
				v-if="!hasUserProfilePhoto"
				class="w-100 h-100 bg-secondary d-flex align-items-center justify-content-center"
			>
				<span> {{ userName }}</span>
			</div>
			<div v-else class="w-100 h-100 bg-transparent">
				<img
					:src="`${$api}/${userPhotoPath}`"
					alt="User profile"
					width="100%"
					height="100%"
					style="object-fit: cover"
				/>
			</div>
		</div>
		<div class="dropdown-menu mt-1" :class="{ show: isOpen }">
			<div @click="closeMenu">
				<router-link :to="{ name: 'edit-user-account-details' }" class="dropdown-item"
					>Update Profile</router-link
				>
			</div>

			<div @click="closeMenu">
				<router-link :to="{ name: 'user-orders' }" class="dropdown-item">Order history</router-link>
			</div>

			<button
				class="dropdown-item logout"
				@click="
					closeMenu()
					logout()
				"
			>
				Logout
			</button>
		</div>
	</div>
</template>

<script>
export default {
	name: 'UserDropDown',
	data() {
		return {
			isOpen: false,
		}
	},
	computed: {
		userName() {
			return this.$store.state.user.firstName[0]
		},
		hasUserProfilePhoto() {
			return this.$store.state.user.photoPath
		},
		userPhotoPath() {
			return this.$store.state.user.photoPath
		},
	},
	methods: {
		closeMenu() {
			this.isOpen = false
			this.$emit('close')
		},
		logout() {
			localStorage.removeItem('isLogin')
			this.$store.dispatch('LOGOUT')
			this.$notify({
				title: 'Logout',
				message: 'Logout successfully!',
				type: 'success',
			})

			// if not home page then push to home page
			if (this.$route.name !== 'home') {
				this.$router.push({ name: 'home' })
			}
		},
	},
}
</script>

<style scoped>
.w-8 {
	width: 2rem;
}
.h-8 {
	height: 2rem;
}
.dropdown-item.logout {
	padding-left: 0.6rem;
	padding-right: 0.6rem;
}
.dropdown-item:active {
	color: #fff !important;
}
</style>
